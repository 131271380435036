<template>
  <v-container
    id="regular-tables"
    tag="section"
    fluid
  >
    <base-material-card
      inline
      color="indigo"
      icon="mdi-clipboard-text"
      :title="`项目进展列表（共${totalElements}个）`"
      class="px-5 py-3 mt-12"
    >
      <v-row>
        <v-col md="1">
          <v-btn
            elevation="1"
            class="ma-1"
            color="blue mt-0"
            disabled
          >
            <v-icon left>
              fa4 fa-plus
            </v-icon>
            添加
          </v-btn>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model="problemParams.projectName"
            label="项目名"
            solo
            dense
          />
        </v-col>
        <v-col
          md="2"
        >
          <v-select
            v-model="problemParams.timeoutStatus"
            clearable
            :items="industryItems"
            label="项目状态"
            item-text="text"
            item-value="abbr"
            solo
            dense
          />
        </v-col>
        <v-col
          md="2"
        >
          <v-select
            v-model="problemParams.progressType"
            clearable
            :items="progressTypes"
            label="进展类型"
            item-text="text"
            item-value="abbr"
            solo
            dense
          />
        </v-col>
        <v-col
          md="2"
          class="text-right"
        >
          <v-btn
            elevation="1"
            class="ma-1"
            color="blue mt-0"
            @click="probemList"
          >
            <v-icon left>
              fa4 fa-search
            </v-icon>
            搜索
          </v-btn>
        </v-col>
        <v-col
          md="2"
          class="text-right"
        >
          <v-btn
            elevation="1"
            class="ma-1"
            color="blue mt-0"
            @click="exportTable"
          >
            <v-icon left>
              fa4 fa-external-link
            </v-icon>
            导出
          </v-btn>
        </v-col>
      </v-row>
      <v-simple-table>
        <thead>
          <tr>
            <th>项目名称</th>
            <th>超时状态</th>
            <th>进展类型</th>
            <th>创建时间</th>
            <th class="text-right">
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(project, index) in projectList"
            :key="index"
          >
            <td>{{ project.data.projectName }}</td>
            <td
              :class="overtimeColor(project.createdAt, project.data.feedback_date)"
            >
              <v-chip
                class="ma-2"
                outlined
                pill
              >
                {{ overtimeText(project.createdAt, project.data.feedback_date) }}
                <v-icon
                  right
                >
                  mdi-clock-time-four
                </v-icon>
              </v-chip>
            </td>
            <td>{{ project.data.progressType }}</td>
            <td>{{ project.createdAt | dateformat }}</td>
            <td class="text-right">
              <!-- <v-btn
                class="px-2 ml-1"
                min-width="0"
                small
                color="error"
                @click="deleteProject(index)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-trash-o
                </v-icon>
                删除
              </v-btn> -->
              <v-btn
                class="px-2 ml-1"
                min-width="0"
                small
                color="success"
                :to="`report-details/${project.formId}/${project.parentId}`"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-eye
                </v-icon>
                查看
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <div class="py-3" />
  </v-container>
</template>
<script>
  export default ({
    data: () => ({
      problemParams: {
        progressType: '',
        formId: '',
        timeoutStatus: '',
        projectName: '',
      },
      progressTypes: [{
                        text: '日进展',
                        abbr: '日进展',
                      },
                      {
                        text: '周进展',
                        abbr: '周进展',
                      },
                      {
                        text: '月进展',
                        abbr: '月进展',
                      }],
      projectList: [],
      totalElements: '',
      industryItems: [{
                        text: '即将超时',
                        abbr: '0',
                      },
                      {
                        text: '超时',
                        abbr: '5',
                      },
                      {
                        text: '未超时',
                        abbr: '10',
                      }],
    }),
    watch: {
      // 监听路由是否变化，一旦变化重新设置表单id和拉取项目列表
      $route: {
        handler (to, from) {
          this.probemList()
        },
      },
    },
    created () {
      this.probemList()
    },
    methods: {
      async probemList () {
        this.problemParams.formId = JSON.parse(localStorage.getItem('advanceForms')).id
        Object.keys(this.problemParams).forEach((val) => {
          if (!this.problemParams[val]) { delete this.problemParams[val] }
        })
        const list = await this.$axios.get('/reports/query', {
          params: this.problemParams,
        })
        this.projectList = list.data.data.content
        this.totalElements = list.data.data.totalElements
        console.log(list)
      },
      overtimeColor (time, feedbackDate) {
        const nowTime = this.$moment(new Date())
        const targeTime = this.$moment(time).add(feedbackDate, 'd')
        const timeC = targeTime.diff(nowTime, 'd')
        let overtimeC = ''
        if (timeC === 0) {
          overtimeC = 'negotiator_warning'
        } else if (timeC < 0) {
          overtimeC = 'negotiator_error'
        } else {
          overtimeC = 'negotiator_green'
        }
        return overtimeC
      },
      overtimeText (time, feedbackDate) {
        const targeTime = this.$moment(time).add(feedbackDate, 'd')
        let timeCText = ''
        // setInterval(() => {
        const nowTime = this.$moment(new Date())
        const timeC = targeTime.diff(nowTime, 'seconds')
        let overtimeT = ''
        if (timeC < 0) {
          overtimeT = '已超时：'
        } else if (timeC > 86400) {
          overtimeT = '未超时，剩余：'
        } else {
          overtimeT = '即将超时，剩余：'
        }
        timeCText = overtimeT + (Math.floor(timeC / 86400) + '天' + Math.floor((timeC % 86400 / 3600)) + '小时' + Math.floor((timeC % 86400 % 3600 / 60)) + '分' + Math.floor((timeC % 86400 % 3600 % 60)) + '秒')
        return timeCText
        // }, 1000)
      },

      // 导出表格
      exportTable () {
        const searchParame = { ...this.$route.query, ...this.problemParams }
        delete searchParame.page
        console.log(searchParame)
        delete searchParame.size
        delete searchParame.formId
        this.exporting = true
        this.exportFile('reports/' + this.problemParams.formId + '/export', searchParame)
      },
      // 导出文件
      async exportFile (url, params) {
        let data
        try {
          data = await this.$axios.get(url, {
            responseType: 'blob',
            params,
          })
        } catch (e) {
          console.log(e)
        }
        this.exporting = false
        if (!data || !data.data || data.data.length < 200) {
          alert('导出失败，请稍后再试')
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL ? window.URL.createObjectURL(new Blob([data.data], { type: 'application/vnd.ms-excel' })) : window.webkitURL.createObjectURL(new Blob([data.data], { type: 'application/vnd.ms-excel' }))
        link.setAttribute('download', '导出表格.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
    },
  })
</script>
<style scoped lang='scss'>
.negotiator_warning{
  span{
    color: #f4ab51;
    border-color: #f4ab51;
  }
}
.negotiator_green{
  span{
    color: #1fc856;
    border-color:#1fc856 ;
  }

}
.negotiator_error{
  span{
    color: #fb541e;
    border-color:#fb541e;
  }

}

</style>
